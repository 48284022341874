import { setAuthenticationTokens } from "./actions/actions";
import axios from "axios";

(function () {
    if (window && window.Event) {
        if (typeof window.CustomEvent === "function") return false;

        // noinspection JSAnnotator
        function CustomEvent(event, params) {
            params = params || {bubbles : false, cancelable : false, detail : undefined};
            var evt = document.createEvent("CustomEvent");
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        }
        CustomEvent.prototype = window.Event.prototype;

        window.CustomEvent = CustomEvent;
    }
})();

class HttpClientProviderCommon {

     /**
      * 
      * @param {*} axios 
      * @param {*} platform 
      * @param {*} store 
      * @param {*} LocalStorageProvider 
      */
    constructor(axios, platform, store, LocalStorageProvider) {
        this.LocalStorageProvider = LocalStorageProvider;
        if (axios) {
            this.axios = axios;
            this.axios.interceptors.response.use(undefined, function (error) {
                let err = error.response;
                //Unknown and untreatable error
                if (!err)
                    return Promise.reject(error);

                if (err.status >= 500) {
                    let m_result = this.i18nProvider.Texti18n(err.data.errorCode);
                    if (m_result) {

                        if (err.data.details && err.data.details > 0) {
                            let keys = Object.keys(err.data.details[0]);

                            keys.forEach((elem, index) => {
                                m_result = m_result.replace("${" + elem + "}", Object.values(err.data.details[0])[index]);
                            });
                        }
                        err.data.message = m_result;
                    }

                    if (err.config && err.config.__isRetryRequest) {
                        if (window.Event) {
                            let event = new CustomEvent("sessionExpired");           
                            window.dispatchEvent(event);
                        }
                    } 

                    return Promise.reject(JSON.stringify(err.data));
                } else if (err.status === 401 && err.config && !err.config.__isRetryRequest && err.config.url.indexOf("oauth/authorize") === -1) {
                    return new Promise(function (resolve, reject) {

                        this.refreshLogin(this.getRefreshToken(),
                            function (access_token, refresh_token) {
                                //dispatch event to replace access and refresh tokens
                                let event = new CustomEvent("loginEvents");
                                event.message = "changeTokens";
                                event.access_token = access_token;
                                event.refresh_token = refresh_token;
                                window.dispatchEvent(event);

                                //retry the old request with the new access token
                                error.config.__isRetryRequest = true;
                                error.config.headers.Authorization = "Bearer " + access_token;
                                resolve(axios(error.config));
                            }.bind(this),
                            function (error) {
                                let isUfeRendered = store.getState().shell.isUfeRendered;
                                //if the refresh token doesnt work, destroy them and the session
                                if (isUfeRendered) {
                                    let event = new CustomEvent("authorizationFailedEvents");
                                    event.message = "tokenHasExpired";
                                    window.dispatchEvent(event);
                                } else {
                                    window.location.hash = "/login";
                                    store.dispatch(setAuthenticationTokens("", ""));
                                    LocalStorageProvider.clear();
                                }
                                //log on the console the error TODO: loging for now, may be removed
                                console.log(error);
                                reject(error);
                            });
                    }.bind(this));
                } else if (err.status === 401 && err.config && err.config.__isRetryRequest) {
                    //if it is a request that already was retried
                    return Promise.reject(error);
                } else {
                    //normal errors, not doing anything with them
                    return Promise.reject(error);
                }
            }.bind(this));
        }
    }

    refreshToken = () => {
        return new Promise(
            (resolve, reject) => {
                this.refreshLogin(
                    this.getRefreshToken(),
                    (access_token, refresh_token) => {
                        let event = new CustomEvent("loginEvents");
                        event.message = "changeTokens";
                        event.access_token = access_token;
                        event.refresh_token = refresh_token;
                        window.dispatchEvent(event);
                        resolve(access_token);
                    },
                    (error) => {
                        let isUfeRendered = this.store.getState().shell.isUfeRendered;
                        reject(error);
                        if (isUfeRendered) {
                            let event = new CustomEvent("authorizationFailedEvents");
                            event.message = "tokenHasExpired";
                            window.dispatchEvent(event);
                        } else {
                            window.location.hash = "/login";
                            this.store.dispatch(setAuthenticationTokens("", ""));
                            this.LocalStorageProvider.clear();
                        }
        
                        //log on the console the error TODO: loging for now, may be removed
                        console.log(error);
                    }
                );
            }
        );
    };

    /**
     * 
     * @param {*} store 
     */
    setStore(store) {
        this.store = store;
    }

    /**
     * [setDefaultContentUrl Set the default base url on this provider]
     * @param {String} contentUrl [contentUrl that will be added to the requests]
     */
    setDefaultContentUrl(contentUrl) {
        this.contentUrl = contentUrl;
    }

    /**
     * [setDefaultHostPort Set the default base urlon this provider]
     * @param {String} hostPort [hostPort that will be added to the requests]
     */
    setDefaultHostPort(hostPort) {
        this.hostPort = hostPort;
    }

    setI18nProvider(i18n) {
        this.i18nProvider = i18n.i18nProvider;
    }

    /**
     * [getRefreshToken Method that will retrieve the refreshToken]
     * @return {Promise} [Promise that will know how to retrieve the refreshToken]
     */
    getRefreshToken() {
        return this.LocalStorageProvider.get("refreshToken");
    }

    /**
     * [getAccessToken Method that will retrieve the acessToken]
     * @return {Promise} [Promise that will know how to retrieve the accessToken]
     */
    getAccessToken() {
        return this.LocalStorageProvider.get("accessToken");
    }

    /**
     * [refreshLogin Method responsible for refresh the authentication. It will make the request to refresh the authentication using the refreshToken, using axios.
     *  The promise will propagate the result up by using the success or error function that enters as param]
     * @param  {function} retrieveRefreshToken [function that gives the current refreshToken in the LocalStorage]
     * @param  {function} sucess               [callback to be executed if the refreshLogin is completed sucessfully]
     * @param  {function} error                [callback to be executed if the refreshLogin is completed unsucessfully]
     * @return {null}]
     */
    refreshLogin(retrieveRefreshToken, sucess, error) {
        retrieveRefreshToken.then(function (sucess, error, refreshToken) {
            if (this.axios) {
                this.axios.post(this.hostPort + "/bin/mvc.do/foundation/v3/oauth/token", {"token" : refreshToken}, {
                    headers : {"Content-Type" : "application/json"},
                    "__isRetryRequest" : true
                })
                    .then(function (sucess, response) {
                        sucess(response.data.accessToken, response.data.refreshToken);
                    }.bind(this, sucess))
                    .catch(function (error, errorResponse) {
                        error(errorResponse);
                    }.bind(this, error));
            }
        }.bind(this, sucess, error)).catch(() => {
        });
    }

    /**
     * 
     */
    getCacheConfig() {
        if (this.axios) {
            let url = this.hostPort + "/bin/mvc.do/content/digitaljourney/foundation/v3/canvas/ufe/config/cacheConfig.json";
            let headers = {"Cache-Control" : "no-store"};
            return this.axios.get(url, {headers});
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param timeout
     * @param responseType
     */
    get(url, headers, params, timeout, responseType) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType);
            return this.axios.get(url, configObj);
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    post(url, headers, params, data, timeout, responseType, auth) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType, auth);
            return this.axios.post(url, data, configObj);
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    put(url, headers, params, data, timeout, responseType) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType);
            return this.axios.put(url, data, configObj);
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param timeout
     * @param responseType
     */
    delete(url, headers, params, timeout, responseType) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType);
            return this.axios.delete(url, configObj);
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    patch(url, headers, params, data, timeout, responseType) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType);
            return this.axios.patch(url, data, configObj);
        }
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param timeout
     * @param responseType
     */
    options(url, headers, params, timeout, responseType) {
        if (this.axios) {
            let configObj = this.generateConfigObj(params, headers, timeout, responseType);
            return this.axios.delete(url, configObj);
        }
    }

    /**
     * 
     * @param {*} params 
     * @param {*} headers 
     * @param {*} timeout 
     * @param {*} responseType 
     * @param {*} auth 
     */
    generateConfigObj(params, headers, timeout, responseType, auth) {
        let configObj = {};
        if (params) {
            configObj.params = params;
        }
        if (headers) {
            configObj.headers = headers;
        }
        if (timeout) {
            configObj.timeout = timeout;
        }
        if (responseType) {
            configObj.responseType = responseType;
        }
        if (auth) {
            configObj.auth = auth;
        }
        return configObj;
    }
}

class HttpClientProvider extends HttpClientProviderCommon {
    constructor(Store, LocalStorageProvider) {
        super(axios, null, Store, LocalStorageProvider);
    }
}

const httpClientProvider = (Store, LocalStorageProvider) => {
    return new HttpClientProvider(Store, LocalStorageProvider);
};

export default httpClientProvider;